html, body, #app, #app>div, #root {
    height: 100%
}
#root {
    height: 100%;
    width: 100%;
}

/* React quill fix font and padding */
.ql-container {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
}

/* Used only for readonly variant of editor */
.remove-padding .ql-editor {
    padding: 0px 0px;
}

/* Styling for navigation dropdown */
.dropdown-menu {
    min-width: 60px;
}

.sliderMoveText {
    text-align: center;
    color: grey;
    overflow-wrap: break-word;
    font-size: 3vw !important;
}

.sliderLabelLeft {
    text-align: center;
    font-size: 3vw;
    color: #1976d2;
    position: 'absolute';
    overflow-wrap: break-word;
    transform: translate(0%, -20%)
}

.sliderLabelRight {
    text-align: center;
    font-size: 3vw;
    color: #1976d2;
    position: 'absolute';
    overflow-wrap: break-word;
    transform: translate(-100%, -20%)
}

@media screen and (min-width: 800px) {
    .sliderMoveText {
        text-align: center;
        color: grey;
        font-size: 1rem !important;
    }

    .sliderLabelLeft {
        font-size: 1rem;
        color: #1976d2;
        position: 'absolute';
        transform: translate(0%, -20%)
    }
    
    .sliderLabelRight {
        font-size: 1rem;
        color: #1976d2;
        position: 'absolute';
        transform: translate(-100%, -20%)
    }
}