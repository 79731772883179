.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 64px;
}

.login-title {
  font-size: 24px;
  margin-bottom: 16px;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.login-input {
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-button {
  padding: 8px 8px;
  width: 250px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  font-style: italic;
  font-size: 14px;
  margin-top: 4px;
  align-self: flex-end;
}